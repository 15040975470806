@font-face {
    font-family: inter;
    src: url(/public/font/inter/inter.ttf);
}

:root{
    --header-height : 64px;
    --scrollbar-width : 5px;
    /* --main-color : #0555e1; */
    --secondary-text-color : #6f7287;
    --secondary-button-color : #f7f6f9;
}

.content{
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: calc(100vh);
}

.main-content{
    /* min-height: 92vh;
    background: #f7fafc; */
    background:#fff;
    margin-top: -64px;
    min-height: calc(100vh);
}

.card-overlay {
    background: rgba(0, 0, 0, 0.4);
}

.card-shadow{
    box-shadow: 0 5px 10px rgb(154 160 185/5%), 0 15px 40px rgb(166 173 201/20%);
}

.hidden{
    opacity: 0;
    filter: blur(1px);
    transform: translateY(-50%);
}

.show{
    transition: all 800ms;
    transition-delay: calc(100ms * var(--order));
    filter: blur(0);
    transform: translateY(0);
    opacity: 1;
}

@media(prefers-reduced-motion){
    .show {
        transition: none;
    }
}